var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[(_vm.newCoupon)?_c('div',{staticClass:"coupons-overlay",on:{"click":function($event){_vm.newCoupon=false}}}):_vm._e(),_c('page-header',{attrs:{"title":_vm.$t('discounts.title'),"btn-title":_vm.$t('discounts.new_btn'),"route-name":'discounts-create'}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.filterContent,"searchPlaceholder":_vm.$t('common.search') + ' ...',"btnTitle":_vm.$t('common.filter_data'),"menuTitle":_vm.$t('common.order_by') + ' : ',"subTitle":'asdf',"orderByItems":[
      {
        name: this.$i18n.t('common.newest'),
        id: 'desc',
      },
      {
        name: this.$i18n.t('common.older'),
        id: 'asc',
      },
      {
        name: this.$i18n.t('common.alphabetical'),
        id: 'name',
      } ]},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderData}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 "},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table"},[(_vm.getData)?_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"page-number":_vm.filterData.page,"items":_vm.getData,"fields":_vm.tableFields,"custimized-items":[

              { name: 'start_at' },
              { name: 'expire_at' },
              { name: 'category_id' },
              { name: 'discount_on' },
              { name: 'approved' },
              { name: 'status' },
              { name: 'status' },
              { name: 'action' } ]},scopedSlots:_vm._u([{key:"start_at",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.start_at, 'YYYY/MM/DD'))+" ")])])]}},{key:"expire_at",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.expire_at, 'YYYY/MM/DD'))+" ")])])]}},{key:"category_id",fn:function(ref){
            var data = ref.data;
return [_c('discount-category',{attrs:{"category":data.item.category_id}})]}},{key:"discount_on",fn:function(ref){
            var data = ref.data;
return [_c('discount-on',{attrs:{"on":data.item.discount_on,"on-type":data.item.discount_on_type}})]}},{key:"approved",fn:function(ref){
            var data = ref.data;
return [_c('discount-approve-status-badge',{attrs:{"status":data.item.approved}})]}},{key:"status",fn:function(ref){
            var data = ref.data;
return [_c('wameed-switch',{attrs:{"disabled":data.item.status===3,"checked":data.item.status ===1},on:{"onChange":function (){ return _vm.onChange(data.item); }}})]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{staticClass:"table-option",attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-8","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":_vm.goToDetail(data.item.id,data.item.category_id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary permission-show-detail"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)]),_c('b-dropdown-item',{staticClass:"permission-edit-item",attrs:{"to":_vm.goToUpdate(data.item.id,data.item.category_id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('edit-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('btn.edit'))+" ")],1)]),_c('b-dropdown-item',{staticClass:"permission-delete-item",on:{"click":function($event){_vm.deleteModal = true; _vm.currentItemData = data.item}}},[_c('span',{staticClass:"text-regular-14  text-danger"},[_c('trash-icon',{staticClass:"mx-2 "}),_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")],1)])],1)]}}],null,false,1078136899)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotal)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotal.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)]),_c('wameed-feedback-modal',{attrs:{"variant":"danger","iconName":"trash-icon","visible":_vm.deleteModal,"title":_vm.$t('discounts.modal.delete'),"subTitle":_vm.$t('discounts.modal.delete_desc'),"btnTitle":_vm.$t('btn.delete')},on:{"close":function($event){_vm.deleteModal = false},"onClick":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }